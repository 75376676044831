import React, { useState } from "react";
import { Box, Button, Avatar, Grid, TextField, Link } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { loginFailure, loginSuccess, loginStart } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link as RouterLink, useNavigate} from "react-router-dom";
import { ROUTES } from "../utils/values";
import Error from "./Error";
import axiosClient from "../utils/client";
import { useTheme } from "styled-components";
import "../css/signin.css";
import Logo from "../images/logo.png";


const SignInModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();


  const handleLogin = async (e) => {
    e.preventDefault();
    //loading login state
    dispatch(loginStart());
    try {
      const res = await axiosClient.post("/api/auth/jwt/signin", {
        email,
        password,
      });
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userPassword", password);
      localStorage.setItem("ServiceToken", res?.data?.serviceToken);
      dispatch(loginSuccess(res.data));
    } catch (err) {
      setError(err.response.data.message);
      dispatch(loginFailure());
    }
  };

  if (currentUser) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  const handleOpen = () => setIsOpen(true);
  // const handleClose = () => setIsOpen(false);
  const handleClose = () => {
    setIsOpen(false);
    navigate("/");
  }
  const handleSignIn = (event) => {
    event.preventDefault();
    // Handle sign-in here
  };
    
      return (
        <div>
          {isOpen && (
            <div className="sign-in-modal">
               <section class="container forms">
               <div class="form login">
                   <div class="form-content">
                        <span className="signin-close" onClick={handleClose}>&times;</span>
                       <div className="signin-logo-container">
                        <img src={Logo} alt="Yivster Logo" />
                        <h2>Sign in to Yivster</h2>
                        </div>
                       <form onSubmit={handleSignIn}>
                           <div class="field input-field">
                           <label htmlFor="signin-email">Email Address</label>
                               <input 
                               placeholder="Email" 
                               class="input"
                               required
                               fullWidth
                               name="signin-email"
                               label="email"
                               type="email"
                               id="signin-email"
                               autoComplete="current-email"
                               onChange={(e) => setEmail(e.target.value)}
                               />
                           </div>
                           <div class="field input-field">
                            <label htmlFor="signin-password">Password</label>
                               <input 
                               type="password" 
                               placeholder="Password" 
                               class="password"
                               required
                               fullWidth
                               name="password"
                               label="Password"
                               id="password"
                               autoComplete="current-password"
                               onChange={(e) => setPassword(e.target.value)}
                               />
                               <i class='bx bx-hide eye-icon'></i>
                           </div>
                         {/*   <div class="form-link">
                               <a href="#" class="forgot-pass">Forgot password?</a>
                           </div> */}
                              {error && <Error>{error}</Error>}
                           <div class="field button-field">
                               <button type="submit" onClick={handleLogin}>Sign In</button>
                           </div>
                       </form>
                       <div class="form-link">
                           <span>Have An Account Already? <Link to="/signup" variant="body2" component={RouterLink} class="link signup-link">Signup</Link></span>
                       </div>
                   </div>
                {/*    <div class="line"></div>
                   <div class="media-options">
                       <a href="#" class="field facebook">
                           <i class='bx bxl-facebook facebook-icon'></i>
                           <span>Login with Facebook</span>
                       </a>
                   </div>
                   <div class="media-options">
                       <a href="#" class="field google">
                           <img src="#" alt="" class="google-img"/>
                           <span>Login with Google</span>
                       </a>
                   </div> */}
               </div>
               </section>
            </div>
          )}
        </div>
      );
    }
  
export default SignInModal;
