import React, { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Box, Button, Avatar, Grid, TextField, Link } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { loginFailure, loginSuccess, loginStart } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../utils/values";
import axiosClient from "../utils/client";
import { useTheme } from "styled-components";
import "../css/signup.css";
import Logo from "../images/logo.png";
import {
  signupStart,
  signupFailure,
} from "../redux/userSlice";
import Error from "./Error";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
  } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Signup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [error, setError] = useState(null);
    const [signUpError, setsignUpError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [userType, setUserType] = useState(null);
    const [isDiabled, setIsDisabled] = useState(true);
    const [isChecked, setIsChecked] = useState(null);
    const [toShowCheckBox, setToShowCheckBox] = useState(false);
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    
    useEffect(() => {
      // console.log('userType', userType)
      // console.log('isDiabled', isDiabled)
      // console.log('isChecked', isChecked)
      if (userType === "user") {
        setToShowCheckBox(false);
        setIsDisabled(false);
      } else if (userType === "vendor") {
        setToShowCheckBox(true);
      }
      if (userType === "vendor" && isChecked) {
        // console.log('asdasdasd')
        setIsDisabled(false);
        // console.log('isDiabled*******', isDiabled)
      }
    }, [userType, isDiabled, isChecked]);
  
    const handleSignUp = async (e) => {
      e.preventDefault();
      dispatch(signupStart());
  
      try {
        const res = await axiosClient.post("/api/auth/signup", {
          name,
          username,
          email,
          password,
          userType,
        });
  
        // console.log('***********', res.data);
        setSuccess(res.data);
  
        if (res.data) {
          // console.log('userType', userType)
  
          if (userType === "vendor") {
            const createAccount = await axiosClient.post("/api/auth/create/account", {
              email,
            });
            console.log("createAccount.data", createAccount.data);
            if (createAccount.data) {
              window.open(createAccount.data.url, "_blank");
            }
          }
        }
      } catch (err) {
        setsignUpError(err.response.data.message);
        dispatch(signupFailure());
        setTimeout(() => {
          setsignUpError(false);
        }, 10000);
        // console.log(err);
        // console.log(err.response.data);
        // console.log(err);
        // console.log(err.response.data);
      }
    };
  
    const handleOpen = () => setIsOpen(true);

    const handleClose = () => {
      setIsOpen(false);
      navigate("/");
    }

    const handleSignIn = (event) => {
      event.preventDefault();
      // Handle sign-in here
    };


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div>
     <div>
          {isOpen && (
             <div class="modal-content">
             <span class="close" onClick={handleClose}>&times;</span>
             <div class="logo-container">
               <img src={Logo} alt="Yivster Logo" />
               <h2>Join Yivster today</h2>
             </div>

             <form>
               <label for="name"> Name </label>
               <input 
               className="styled-input"       
                  required
                  id="name"
                  label="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />

               <label for="username"> Username </label>
               <input 
               className="styled-input"
                margin="normal"
                required
                fullWidth
                id="username"
                label="username"
                name="username"
                autoComplete="username"
                autoFocus
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                />

               <label for="password">Password</label>
               <input 
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  />

               <label for="email">Email Address</label>
                <input 
                margin="normal"
                required
                fullWidth
                name="email"
                label="email"
                type="email"
                id="email"
                autoComplete="current-email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                    />
  
            <div className="radio-group">
              <input type="radio" id="vendor" name="userType" value="vendor" onChange={(e) => setUserType(e.target.value)} />
              <label htmlFor="vendor">Creator</label>

              <input type="radio" id="user" name="userType" value="user" onChange={(e) => setUserType(e.target.value)} />
              <label htmlFor="user">User</label>
            </div>

            {toShowCheckBox ? (            
                <div className="checkbox-group" >
                <input type="checkbox" id="agree" name="agree" onChange={(e) => setIsChecked(e.target.checked)}/>
                <label for="agree"> I agree this will redirect to the stripe page.</label>
                </div>
              ) : (
                <></>
              )}
              {signUpError ? (
                <Error> {signUpError} </Error>
              ) : (
                success && <Error variant="success"> {success} </Error>
              )}
               {/* <input type="checkbox" id="terms" name="terms"/> */}
               <button className="sign-up-btn" type="submit"  onClick={handleSignUp}>Sign Up</button>
               <label for="terms"> By signing up I agree to the Terms of Service and Privacy Policy</label>
             </form>
             <p className="account"> Have An Account Already? <Link to="/signin" variant="body2" component={RouterLink}> Login </Link></p>
           </div>
          )}
        </div>
  </div>
  );
};
/* finish final touches on signup and 
sign in remove buttons have it automatically open on redirect and once x is clicked habe it close ad redirect home */
export default Signup;

