import { format } from "timeago.js";
import { Avatar, Box } from "@mui/material";
import { useTheme } from "styled-components";

const CommentReply = ({ reply }) => {
  const theme = useTheme();

  return (
    <div className="reply">
      <div className="author-info">
        <Avatar
          sx={{
            height: "30px",
            width: "30px",
          }}
          src={reply?.userImg ? reply?.userImg : ""}
          alt="User Avatar"
        />
        <Box>
          <span
            className="username"
            style={{ color: 'var(--text-main)' }}
          >
            {reply.userName}
          </span>
          &nbsp;
          <span
            className="timestamp"
            style={{ color: 'var(--text-main)' }}
          >
            {format(reply.createdAt)}
          </span>
          <p className="content" style={{ color: 'var(--text-main)' }}>
            {reply.reply}&nbsp;
          </p>
        </Box>
      </div>
    </div>
  );
};

export default CommentReply;
