import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axiosClient from "../../utils/client";

import { Box, Typography, Button, SvgIcon, Divider } from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";
import { subscription } from "../../redux/userSlice";

import ChannelCards from "./ChannelCards";
import ChannelAbout from "./ChannelAbout";
import {
  StyledChannelBanner,
  StyledTabButton,
  StyledAvatar,
  StyledChannelIntroBox,
  StyledChannelBannerWrapper,
} from "./styles";

import { useTheme } from "styled-components";

const Channel = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [tabValue, setTabValue] = useState(0);
  const [channel, setChannel] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const [videos, setVideos] = useState([]);
  const [isLoadingVideos, setIsLoadingVideos] = useState(false);
  const [totalViews, setTotalViews] = useState(0);

  const theme = useTheme();

  const handleTabChange = (event) => {
    setTabValue(+event.target.attributes.dataindex.value);
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const channelRes = await axiosClient.get(`/api/users/find/${id}`);
        setChannel(channelRes?.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData(id);
  }, [id]);

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoadingVideos(true);
      try {
        const videoRes = await axiosClient.get(`/api/videos/channel/${id}`);
        setVideos(videoRes?.data);
        const totalViews = videoRes.data.reduce(
          (sum, video) => sum + video.views,
          0
        );
        setTotalViews(totalViews);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoadingVideos(false);
      }
    };
    fetchVideos();
  }, [id]);

  const handleSub = async () => {
    currentUser.subscribedUsers.includes(channel?._id)
      ? await axiosClient.put(`/api/users/unsub/${channel?._id}`)
      : await axiosClient.put(`/api/users/sub/${channel?._id}`);
    dispatch(subscription(channel?._id));
  };

  const setActive = (value, index) =>
    value === index ? "contained" : "outlined";

  return (
    <Box component="main">
      <StyledChannelBanner
        sx={{ backgroundImage: `url(${channel?.channelInfo?.channelBanner})` }}
      />
      <Box
        variant="outlined"
        sx={{
          mx: -3,
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
        }}
      >
        <StyledChannelIntroBox>
          <StyledAvatar src={channel?.img} />
          <Box>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "var(--text-main)" }}
            >
              {channel?.username}&nbsp;
            </Typography>
            <Box>
              <Typography
                style={{ color: "var(--text-main)" }}
                variant="body1"
                component="span"
                sx={{
                  mr: 2,
                  fontWeight: "bold",
                  color: "var(--text-main)",
                }}
              >
                {channel?.subscribers} subscribers
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ml: "auto", py: 2 }}>
            {currentUser && (
              <Button
                variant="contained"
                onClick={handleSub}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PersonIcon />
                  </SvgIcon>
                }
              >
                {currentUser?.subscribedUsers?.includes(channel?._id)
                  ? "SUBSCRIBED"
                  : "SUBSCRIBE"}
              </Button>
            )}
          </Box>
        </StyledChannelIntroBox>
        <Divider />
        <Box sx={{ px: 3, py: 2 }}>
          <StyledTabButton
            onClick={handleTabChange}
            dataindex="0"
            variant={setActive(tabValue, 0)}
          >
            Videos
          </StyledTabButton>
          <StyledTabButton
            onClick={handleTabChange}
            dataindex="1"
            variant={setActive(tabValue, 1)}
          >
            About
          </StyledTabButton>
        </Box>
        <Divider />
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <Box>
          {channel?._id && (
            <ChannelCards isLoadingVideos={isLoadingVideos} videos={videos} />
          )}
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <Box sx={{ py: 3 }}>
          <ChannelAbout
            channel={channel}
            totalVideos={videos.length}
            totalViews={totalViews}
          />
        </Box>
      </CustomTabPanel>
    </Box>
  );
};

export default Channel;
