import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { Alert } from "react-bootstrap";
import axiosClient from '../utils/client';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.655);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  background-color: #343a40;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: transparent;
  z-index: 999;
  width: 100%;
`;

const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  width: 100%;
`;

const Button = styled.button`
  border-radius: ${({ copied }) => (copied ? "10px" : "10px")};
  border: none;
  padding: ${({ copied }) => (copied ? "10px" : "10px")}
    ${({ copied }) => (copied ? "20px" : "20px")};
  font-weight: ${({ copied }) => (copied ? "500" : "")};
  cursor: pointer;
  background-color: ${({ copied }) => (copied ? "#a7282c" : "#3f8efc")};
  color: white;

  &::hover {
    background-color: #1e7e34;
  }
`;

const Label = styled.label`
  font-size: ${({ copied }) => (copied ? "14px" : "")};
`;

const StripeModal = ({
  setOpenModal,
  videoId,
  channelName,
  defaultAmount = 5,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [inputAmount, setIntputAmount] = useState(defaultAmount);
  const [isValid, setIsValid] = useState(false);

  const payNow = async () => {
    try {
      const response = await axiosClient.post("/api/users/send-tip", {
        amount: inputAmount * 100,
        videoId,
      });
      // console.log('response', response)
      if (response.data) {
        window.open(response.data.url, "_blank");
      }
      setOpenModal(false);
      setIsValid(true);
    } catch (err) {
      let message = channelName + 'can not receive tips right now.'
      setIsCopied(true);
      setTimeout(() => setOpenModal(false), 2000);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpenModal(false)}>X</Close>
        <Title> Send {channelName} A Tip </Title>
        <Label>Amount</Label>
        <Input
          type="text"
          placeholder="Enter Amount"
          value={inputAmount}
          onInput={(e) => setIntputAmount(e.target.value)}
        ></Input>

        <Button onClick={payNow} copied={isCopied}>
        {isCopied ? channelName + " can not receive tips right now" : "Tip"}
        </Button>
      </Wrapper>
      {isValid ? (
        <Alert variant="success">Thank you for the payment!</Alert>
      ) : (
        ""
      )}
    </Container>
  );
};

export default StripeModal;
