import React, { useEffect, useState } from "react";
import { format } from "timeago.js";
import { useDispatch, useSelector } from "react-redux";
import { createComment } from "../redux/commentSlice";
import RepliesList from "./RepliesList";
import { Button, Box, Avatar, Input } from "@mui/material";
import { useAlert } from "react-alert";
import axiosClient from "../utils/client";
import { useTheme } from "styled-components";
import { useLocation, Link } from "react-router-dom";

import { Reply as ReplyIcon } from "@mui/icons-material";
import Comments from "../components/Comments";

const Comment = ({ comment, videoId }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [channel, setChannel] = useState({});
  const [hideReplyForm, setHideReplyForm] = useState(true);
  const [newReplyContent, setNewReplyContent] = useState("");
  const alert = useAlert();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchComment = async () => {
      const res = await axiosClient.get(`/api/users/find/${comment.userId}`);
      setChannel(res.data);
    };
    fetchComment();
    //everytime there is a new comment by a new user the function will fire
  }, [comment.userId, dispatch]);
  const handleAddReply = async () => {
    try {
      const res = await axiosClient.post(`/api/comments/reply`, {
        reply: newReplyContent,
        videoId: videoId,
        commentId: comment._id,
      });
      //desctructing all of the comments and adding the new comment to the array
      // setComments([...comments, res.data]);

      dispatch(createComment(res.data));
      setNewReplyContent("");
      // window.location.reload()
    } catch (error) {
      alert.error("You are not authorized!");
      console.log("error", error.message);
    }
  };

  const handleHideReplyForm = () => {
    setHideReplyForm((prev) => !prev);
  };

  const theme = useTheme();

  return (
    <div className="comment">
      <div className="author-info">
        <Link to={`/channel/${channel._id}`}>
          <Avatar
            src={comment?.userImg ? comment?.userImg : ""}
            alt="User Avatar"
          />
        </Link>
        <Box sx={{ flexGrow: 1 }}>
          <span
            className="username"
            style={{ color: 'var(--text-main)' }}
          >
            {comment.userName}
          </span>
          &nbsp;
          <span
            className="timestamp"
            style={{ color: 'var(--text-main)'}}
          >
            {format(comment.createdAt)}
          </span>
          <p
            className="comment-content"
            style={{ color: 'var(--text-main)' }}
          >
            {comment.desc}
          </p>
          <Button
            size="small"
            sx={{ textTransform: "none", color:'var(--text-main)' }}
            onClick={handleHideReplyForm}
            startIcon={<ReplyIcon />}
          >
            Reply
          </Button>
          <div className="reply-form" hidden={hideReplyForm}>
            <div className="new-comment">
              <Avatar
                sx={{ height: 30, width: 30 }}
                src={currentUser?.img ? currentUser?.img : ""}
                alt="User Avatar"
              />
              <Input
                size="small"
                value={newReplyContent}
                placeholder="Add a reply..."
                sx={{ flexGrow: 1 }}
                onChange={(e) => setNewReplyContent(e.target.value)}
                style={{ color:'var(--text-main)' }}
              />
              <Button variant="contained" onClick={handleAddReply}>
                Reply
              </Button>
            </div>
          </div>
        </Box>
      </div>
      {comment.replies && comment.replies.length > 0 ? (
        <RepliesList replies={comment.replies} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Comment;
