import React, { useState } from "react";
import styled from "styled-components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axiosClient from "../utils/client";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.655);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  background-color: #343a40;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Input = styled.input`
  border-radius: ${({ copied }) => (copied ? "10px" : "10px")};
  border-color: ${({ copied }) => (copied ? "#28a745" : "#6c757d")};
  color: ${({ copied }) => (copied ? "#28a745" : "white")};
  background-color: ${({ copied }) => (copied ? "#343a40" : "transparent")};
  padding: ${({ copied }) => (copied ? "10px" : "10px")};
  margin-bottom: ${({ copied }) => (copied ? "20px" : "20px")};
  z-index: ${({ copied }) => (copied ? "999" : "999")};
  width: ${({ copied }) => (copied ? "100%" : "100%")};
  transition-duration: 0.3s;

  &::placeholder {
    color: ${({ copied }) => (copied ? "#28a745" : "#6c757d")};
  }
`;

const Desc = styled.textarea`
  border-radius: ${({ copied }) => (copied ? "3px" : "0")};
  border-color: ${({ copied }) => (copied ? "#28a745" : "#6c757d")};
  color: ${({ copied }) => (copied ? "#28a745" : "white")};
  background-color: ${({ copied }) => (copied ? "#343a40" : "transparent")};
  padding: ${({ copied }) => (copied ? "10px" : "0")};
  width: ${({ copied }) => (copied ? "100%" : "0")};
  transition-duration: 0.3s;

  &::placeholder {
    color: ${({ copied }) => (copied ? "#28a745" : "#6c757d")};
  }
`;

const Button = styled.button`
  border-radius: ${({ copied }) => (copied ? "10px" : "10px")};
  border: none;
  padding: ${({ copied }) => (copied ? "10px" : "10px")}
    ${({ copied }) => (copied ? "20px" : "20px")};
  font-weight: ${({ copied }) => (copied ? "500" : "")};
  cursor: pointer;
  background-color: ${({ copied }) => (copied ? "#28a745" : "#3f8efc")};
  color: white;

  &::hover {
    background-color: #1e7e34;
  }
`;

const Label = styled.label`
  font-size: ${({ copied }) => (copied ? "14px" : "")};
`;

const ReportModal = ({ setOpenModal, videoId, channelName }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChangeRadioGroup = async () => {
    try {
      setIsCopied(true);
      const response = await axiosClient.put(`/api/videos/report-video/${videoId}`, {
        reason: selectedValue,
      });
      if (response.status === 200) {
        setOpenModal(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpenModal(false)}>X</Close>
        <Title> Report Video </Title>
        <Label copied={isCopied}>Reasons</Label>
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <FormControlLabel
              value="Harrasment and Cyberbullying"
              control={<Radio />}
              label="Harrasment and Cyberbullying"
            />
            <FormControlLabel
              value="Privacy Violation"
              control={<Radio />}
              label="Privacy"
            />
            <FormControlLabel
              value="Impersonation"
              control={<Radio />}
              label="Impersonation"
            />
            <FormControlLabel
              value="Violent Threats"
              control={<Radio />}
              label="Violent Threats"
            />
            <FormControlLabel
              value="Child Endangerment"
              control={<Radio />}
              label="Child Endangerment"
            />
            <FormControlLabel
              value="Hate speach against a protected group"
              control={<Radio />}
              label="Hate speach against a protected group"
            />
            <FormControlLabel
              value="Spam and Scams"
              control={<Radio />}
              label="Spam and Scams"
            />
            <FormControlLabel
              value="None of these are my issues"
              control={<Radio />}
              label="None of these are my issues"
            />
          </RadioGroup>
        </FormControl>
        <Button onClick={handleChangeRadioGroup} copied={isCopied}>
          {isCopied
            ? "Thank you for reporting this, our team will review this"
            : "Report"}
        </Button>
      </Wrapper>
    </Container>
  );
};

export default ReportModal;
