import { useState } from "react";
//import { ThemeProvider } from "@mui/material/styles";
//import { useTheme } from "@mui/material/styles";
import styled, { ThemeProvider } from "styled-components";
import { useTheme } from 'styled-components';
import Navbar from "./components/Navbar";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "./utils/Theme";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Newest from "./pages/Newest";
import Trending from "./pages/Trending";
import Subscriptions from "./pages/Subscriptions";
import Video from "./pages/Video";
import SignIn from "./pages/SignIn";
import Search from "./pages/search";
import Channel from "./pages/channel";
import Dashboard from "./pages/Dashboard";
import Terms from "./pages/Terms";
import SignUp from "./pages/SignUp";
import "./assets/css/styles.css";
import "./css/app.css";

import { SIDEBAR_WIDTH } from "./utils/values";
import { MINISIDEBAR_WIDTH } from "./utils/values";
import { TOPCONTENT_HEIGHT } from "./utils/values";

import { useMediaQuery, CssBaseline } from "@mui/material";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

const Container = styled.div`
  /* Add your styles here */
  background-color: var(--primary-color);
  color: var(--main-text-color);
`;

/* const Main = styled("div")(({ theme }) => ({
  flexGorw: 1,
})); */

const Main = styled.div`

`;
//for some reason it can't read the theme from the styled component and background color from theme

/* const Wrapper = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    position: "relative",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 65,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${SIDEBAR_WIDTH}px`,
    }),
  })
); */

// wraps around the the content in the main section
const Wrapper = styled.div`
margin: 0;
padding-top: 60px;
padding-left: 0px;
padding-right: 24px;

  position: relative;
  flex-grow: 1;

  margin-left: ${({ open, isSidebarOpen }) =>
    open && isSidebarOpen ? `${SIDEBAR_WIDTH}px` : `${MINISIDEBAR_WIDTH}px`};

  transition: ${({ open, isSidebarOpen }) =>
    open && isSidebarOpen
      ? "transform 0.4s ease-out"
      : "transform 0.4s ease-out"};

  @media (max-width: 550px) {
    padding-left: 0;
    padding-right: 30;
    margin-left: 30px;
  }

  @media (min-width: 551px) and (max-width: 750px) {
    padding-left: 0;
    padding-right: 10px;
    margin-left: 10px;
  }
`;

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { darkMode } = useSelector((state) => state?.theme);


  // State variable to keep track of whether the sidebar is open or closed
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Function to close the sidebar
  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  return (
    <Provider template={AlertTemplate} {...options}>
      <div className={`app ${darkMode ? 'dark-theme' : ''}`}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}> 
        <Container>
          <BrowserRouter>
            <Main>
              <Navbar
                darkMode={darkMode}
                open={sidebarOpen}
                setOpen={setSidebarOpen}
              />
              <Wrapper
                open={sidebarOpen}
                isSidebarOpen={isSidebarOpen}
                onClick={handleSidebarClose}
              >
                <Routes>
                  <Route path="/">
                    <Route index element={<Home type="random" />} />
                    <Route path="trending" element={<Trending />} />
                    <Route path="subscriptions" element={<Subscriptions />} />
                    <Route path="newest" element={<Newest />} />

                    <Route path="signin" element={<SignIn />} />
                    <Route path="terms" element={<Terms />} />

                    <Route path="search" element={<Search />} />
                    <Route path="signup" element={<SignUp />} />

                    <Route path="channel">
                      <Route path=":id" element={<Channel />} />
                    </Route>

                    <Route path="video">
                      <Route path=":id" element={<Video />} />
                    </Route>

                    <Route path="dashboard">
                      <Route path=":id" element={<Dashboard />} />
                    </Route>
                  </Route>
                </Routes>
              </Wrapper>
            </Main>
          </BrowserRouter>
        </Container>
       </ThemeProvider> 
      </div>
    </Provider>
  );
}

export default App;