import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserPrefferedDarkTheme,
  setUserPrefferedLightTheme,
  setSystemPrefferedDarkTheme,
  setSystemPrefferedLightTheme,
} from "../redux/themeSlice";
import { useTheme } from "@mui/material/styles";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import styled from "styled-components";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Home from "../assets/icons/home.png";
// import Avatar from "../assets/images/Avatar.png";

import "../css/variables.css";

import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  VideoCameraBack as VideoCameraBackIcon,
  Search as SearchIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  SubscriptionsOutlined as SubscriptionsOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  TrendingUp as TrendingUpIcon,
  Schedule as ScheduleIcon,
  ArrowBack as ArrowBackIcon,
  MoreVert as MoreVertIcon,
  Check as CheckIcon,
  NightsStay as DarkMode,
  Brightness2 as DeviceTheme,
  WbSunny as LightTheme,
  Person as UserOutline,
  AddBox as AddBoxIcon,
} from "@mui/icons-material";

import YivLogo from "../assets/images/logo.png";
import SearchBar from "./SearchBar";
import axiosClient from "../utils/client";

import Upload from "./Upload";
import { logout } from "../redux/userSlice";
import { DASHBOARD_URL } from "../defaultValues";
import { Typography } from "@mui/material";
import "../css/navbar.css";


// Define the styled div component for the menu
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 56px;
  right: 0px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  padding: 20px 50px;
  gap: 10px;
  margin-right: 15px;
    }
  `;

const Divider = styled.hr`
  background-color: #606060;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: var(--text-main);
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.soft};
  }
`;

const ListItemIcon = styled.div`
  margin-right: 10px;
`;

const ListItemText = styled.div`
  font-size: 14px;
`;

const Box = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const Backdrop = styled.div`
  position: fixed; // Add this line
  top: 0; // Add this line
  left: 0; // Add this line
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Add this line
  z-index: ${(props) => (props.open ? 10 : -1)};
  display: ${(props) => (props.open ? "block" : "none")};
`;

const Paper = styled.div`
background-color: #f9f9f9;
flex-grow: 1;
display: flex; 
alignSelf: flex-start, 
padding: 8px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  display: flex;
  color: grey;

  @media (max-width: 549px) {
    margin-left: -100px;
  }
`;

// ... other styled components

const Navbar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  //const isDesktop = useMediaQuery("(min-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);
  //const menuOpen = Boolean(anchorEl);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const userMenuOpen = Boolean(userAnchorEl);
  const { darkMode, systemPreffered } = useSelector((state) => state?.theme);
  //gives us access to the current user's state
  const [displayMenu, setDisplayMenu] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  //const [menuOpen, setMenuOpen] = useState(false);
  const [loggedMenu, setLoggedMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("home")
  const [q, setQ] = useState("");
  const [isDarkTheme, setisDarkTheme] = useState(true)

  // Define the media query string
  const mediaQuery = "(min-width: 960px)";
  // Create a MediaQueryList object
  const mediaQueryList = window.matchMedia(mediaQuery);
  // Get the boolean value of the media query
  const isDesktop = mediaQueryList.matches;

  const dispatch = useDispatch();

  const handleThemes = ()=>{
    const rootElement = document.getElementById('root');
    rootElement.classList.toggle('dark-theme')
    setisDarkTheme(!isDarkTheme)
}


  const handleClick = (event) => {
    //setAnchorEl(event.currentTarget);
    // setMenuOpen((prevOpen) => !prevOpen);
    setLoggedMenu(true);
    console.log(loggedMenu); // Add this line
  };

  const handleClose = () => {
    setAnchorEl(null);
    //setMenuOpen(false);
  };

  const handleUserMenuClick = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  const handleSystemTheme = () => {
    handleUserMenuClose();
    handleClose();
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      dispatch(setSystemPrefferedDarkTheme());
    } else {
      dispatch(setSystemPrefferedLightTheme());
    }
  };

const handleUserTheme = (mode) => {
  handleUserMenuClose();
  handleClose();
  if (mode === "dark") {
    dispatch(setUserPrefferedDarkTheme());
  } else {
    dispatch(setUserPrefferedLightTheme());
  }
};

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setOpen(!open);
  };

  const handleUploadRedirect = () => {
    handleClose();
    const email = localStorage.getItem("userEmail");
    const password = localStorage.getItem("userPassword");
    window.open(
      `${DASHBOARD_URL}/login?email=${email}&password=${password}`,
      "_blank"
    );
  };

  const handleRedirect = () => {
    handleClose();
    const email = localStorage.getItem("userEmail");
    const password = localStorage.getItem("userPassword");
    window.open(
      `${DASHBOARD_URL}/login?email=${email}&password=${password}`,
      "_blank"
    );
  };

  const handleLogout = async () => {
    handleClose();
    try {
      await axiosClient.get("/api/users/logout");
      dispatch(logout());
      navigate("/");
    } catch (err) {
      console.log(err.response.data);
    }
  };

  // Function to toggle the menu
  const handleMenuToggle = () => {
    setDisplayMenu(!displayMenu);
  };

  // Function to toggle the menu
  const handleLoggedToggle = () => {
    setLoggedMenu(!loggedMenu);
  };

  const theme = useTheme();
  const [videoOpen, setVideoOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [mobileSearch, setMobileSearchOpen] = useState(false);
  const handleMobileSearchClose = () => {
    setMobileSearchOpen(false);
  };
  const handleMobileSearchOpen = () => {
    setMobileSearchOpen(true);
  };


  const menuId = "primary-search-account-button";
  const RenderMenu = () => (
    <Menu
      id="avatar-menu"
      anchorEl={anchorEl}
      // open={menuOpen}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "avatar-button",
      }}
    >
      <MenuItem disabled opacity="1">
        <Typography>{currentUser?.name}</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleRedirect} component={RouterLink} to="/">
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        Dashboard
      </MenuItem>
      <Divider />
      <MenuItem disabled opacity="1">
        <Typography>Appearance</Typography>
      </MenuItem>
      <Divider />
      <MenuItem sx={{ width: 220 }} onClick={handleSystemTheme}>
        {systemPreffered ? (
          <>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            Use device theme
          </>
        ) : (
          <ListItemText inset>Use device theme</ListItemText>
        )}
      </MenuItem>
      <MenuItem onClick={() => handleUserTheme("dark")}>
        {!systemPreffered && darkMode ? (
          <>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            Dark theme
          </>
        ) : (
          <ListItemText inset>Dark theme</ListItemText>
        )}
      </MenuItem>
      <MenuItem onClick={() => handleUserTheme("light")}>
        {!systemPreffered && !darkMode ? (
          <>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            Light theme
          </>
        ) : (
          <ListItemText inset>Light theme</ListItemText>
        )}
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout} component={RouterLink} to="/">
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Sign Out
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    console.log(loggedMenu);
  }, [loggedMenu]);

  return (
    <>
        <header class="header">
      <div class="left-section">
        <MenuIcon class="hamburger-menu"  onClick={handleSidebarToggle}/>
        {/* <img class="hamburger-menu" src="icons/hamburger-menu.svg"/> */}
        <Link
            to="/"
            style={{ textDecoration: "none" }}
            className="logo-wrapper"
          >
        <img class="youtube-logo" src={YivLogo}/>
        <span className="text-beta"> BETA </span>
        </Link>
      </div>
      <div class="middle-section">
           <Box>
            <IconButton
              style={{ display: isDesktop ? "none" : "block" }}
              onClick={handleMobileSearchOpen}
            >
              <StyledSearchIcon />
            </IconButton>
            {isDesktop ? (
              <Box>
                <SearchBar
                  query={q}
                  handleSearchQuery={setQ}
                  handleMobileSearchClose={handleMobileSearchClose}
                />
              </Box>
            ) : (
              <Backdrop open={mobileSearch}>
                <Paper className="mobile-search-bar">
                  <IconButton onClick={handleMobileSearchClose}>
                    <ArrowBackIcon />
                  </IconButton>
                  <SearchBar
                    query={q}
                    handleSearchQuery={setQ}
                    handleMobileSearchClose={handleMobileSearchClose}
                  />
                </Paper>
              </Backdrop>
            )}
          </Box>
      </div>
      
    <div class="right-section">
    {currentUser ? (
            <>
              {/* <img class="youtube-apps-icon" src="icons/youtube-apps.svg" /> */}
              {/*  
        icon for notifications with tooltip
         <div class="notifications-icon-container">
            <img class="notifications-icon" src="icons/notifications.svg" />
            <div class="notifications-count">3</div>
          </div> */}

         {/*      <div class="upload-icon-container">
                <VideoCameraBackIcon
                  style={{ fill: "grey" }}
                  class="upload-icon"
                  onClick={() => handleUploadRedirect()}
                />
                <div class="tooltip">Upload</div>
              </div> */}

          <Link to="/terms" style={{ textDecoration: "none", color: "inherit"  }}>
              <div class="upload-icon-container">
                      {/* <img class="upload-icon" src="icons/upload.svg"/> */}
                 <MoreVertIcon />
                   <div class="tooltip">Terms & PP </div>
               </div>
                </Link>

                <div class="upload-icon-container">
                      {/* <img class="upload-icon" src="icons/upload.svg"/> */}
                  <AddBoxIcon onClick={() => handleUploadRedirect()} />
                   <div class="tooltip">Upload</div>
               </div>

              <div class="header-icons">
                <div
                  id="avatar-button"
                  aria-controls={displayMenu ? "avatar-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={displayMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img class="current-user-picture" src={currentUser.img}/>

                 {/*  <Avatar
                    style={{ border: "3px solid #3F8EFC" }}
                    src={currentUser.img}
                  /> */}
                </div>
              </div>
              {/* {currentUser && <RenderMenu />} */}
              {currentUser && loggedMenu && (
                <div className="profileBtns">
                   <MenuItem>
                    <IconButton
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                      edge="end"
                      color="inherit"
                      // Close the Menu when the icon is clicked
                      aria-label="close"
                    >
                      <CloseIcon
                        onClick={() => {
                          setLoggedMenu(!loggedMenu);
                        }}
                      />
                    </IconButton>
                  </MenuItem> 

                  <div className="avatarInfo profileTab">
                        <img class="current-user-picture" src={currentUser.img} alt="Your Avatar" title='Avatar' />
                        <p>{currentUser.name}</p>
                    </div>

                    <div className="horizontalLine"></div>

                    <div className="profileTab"  onClick={handleRedirect}
                    component={RouterLink} to="/">
                            <UserOutline />
                            <p>Dashboard</p>
                        </div>

                <div className="horizontalLine"></div>

                  <MenuItem
                    sx={{ width: 220 }}
                    onClick={() => {
                      handleSystemTheme();
                      setLoggedMenu(!loggedMenu);
                    }}
                  >
                    {" "}
                    {systemPreffered ? (
                      <>
                        <div className="profileTab">
                         <CheckIcon />
                          <DeviceTheme />
                            <p>Use Device Theme</p>
                        </div>
                      </>
                    ) : (
                      <div className="profileTab">
                         <DeviceTheme />
                         <p>Use Device Theme</p>
                     </div>
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleUserTheme("dark");
                      setLoggedMenu(!loggedMenu);
                    }}
                  >
                    {" "}
                    {!systemPreffered && darkMode ? (
                      <>
                        <div className="profileTab">
                          <CheckIcon />
                            <DarkMode />      
                            <p>Dark Mode</p>
                        </div>
                      </>
                    ) : (
                      <div className="profileTab">  
                          <DarkMode />      
                        <p>Dark Mode</p>
                    </div>
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleUserTheme("light");
                      setLoggedMenu(!loggedMenu);
                    }}
                  >
                    {!systemPreffered && !darkMode ? (
                      <>
                         <div className="profileTab">
                          <CheckIcon />
                            <LightTheme />
                            <p>Light Mode</p>
                       </div>
                      </>
                    ) : (
                      <div className="profileTab">
                         <LightTheme />
                        <p>Light Mode</p>
                   </div>
                    )}
                  </MenuItem>

                 <div className="horizontalLine"></div>

                  <div className="profileTab"  onClick={handleLogout}
                    component={RouterLink}
                    to="/">
                     <LogoutIcon />
                    <p>Sign out</p>
                     </div>
              </div>
              )}
            </>
          ) : (
                <div class="header-icons">
                  <div class="more-icon-container">
                    <MoreVertIcon style={{marginTop:"5px"}} onClick={handleMenuToggle} />
                    <div class="tooltip"> Menu </div>
                </div>

              {displayMenu && (
               <div className="profileBtns">                  
                  <MenuItem
                    sx={{ width: 220 }}
                    onClick={() => {
                      handleSystemTheme();
                      setDisplayMenu(!displayMenu);
                    }}
                  >
                    {systemPreffered ? (
                      <>
                        <div className="profileTab">
                         <CheckIcon />
                         <DeviceTheme />
                            <p>Use Device Theme</p>
                        </div>
                      </>
                    ) : (
                      <div className="profileTab">
                      <DeviceTheme />
                        <p>Use Device Theme</p>
                  </div>
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleUserTheme("dark");
                      setDisplayMenu(!displayMenu);
                    }}
                  >
                    {!systemPreffered && darkMode ? (
                      <>
                          <div className="profileTab">
                          <CheckIcon />
                            <DarkMode/>
                            <p>Dark Mode</p>
                        </div>
                      </>
                    ) : (
                      <div className="profileTab">
                          <DarkMode/>
                        <p>Dark Mode</p>
                  </div>
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleUserTheme("light");
                      setDisplayMenu(!displayMenu);
                    }}
                  >
                    {!systemPreffered && !darkMode ? (
                      <>
                        <div className="profileTab">
                        <CheckIcon />
                        <LightTheme />
                            <p>Light Mode</p>
                        </div>
                      </>
                    ) : (
                      <div className="profileTab">
                       <LightTheme />
                      <p>Light Mode</p>
                  </div>
                    )}
                  </MenuItem>
                </div>
              )}
{/* 
              <div class="upload-icon-container">
                <button class="signin-btn" onClick={() => navigate("/signin")}>
                  <AccountCircleOutlinedIcon />
                  Sign In
                </button>
              </div> */}
                <div class="upload-icon-container">
                <button class="signin-btn" onClick={() => navigate("/signin")}>
                  <AccountCircleOutlinedIcon />
                  Sign In
                </button>
             </div>
            </div>
          )}
        </div>
  </header>

        {isSidebarOpen ? (
        <div class="sidebarContainer" id='sidebarContainer'>
        <div className="sideBarTabs">
    {/*     <div className="tabContainer">
            {sideBarData.mainTabs.map((tab,i) => {
                return <div key={i} className={`sidebarTab ${tab.icon === selectedId && 'active'}`} onClick={() => setSelectedId(tab.icon)}>
                    <span className='material-symbols-rounded'>{tab.icon}</span>
                    {tab.head}
                </div>
            })}
        </div> */}
        <Link to="/" style={{ textDecoration: "none", color: "inherit"  }}>
          <div className={`sidebarTab ${'home' === selectedId && 'active'}`} onClick={() => setSelectedId('home')}>
              <HomeIcon style={{ fontSize: "28px", marginRight: "18px"}}/>
              Home
        </div>
      </Link>
 
      <Link
            to="/trending"
            style={{ textDecoration: "none", color: "inherit"  }}
            className="logo-wrapper"
          >
      <div className={`sidebarTab ${'trending' === selectedId && 'active'}`} onClick={() => setSelectedId('trending')}>
            <TrendingUpIcon style={{ fontSize: "28px", marginRight: "18px"}}/>
            Trending
      </div>
      </Link>

      <Link
            to="/subscriptions"
            style={{ textDecoration: "none", color: "inherit"  }}
          >
      <div className={`sidebarTab ${'subscriptions' === selectedId && 'active'}`} onClick={() => setSelectedId('subscriptions')}>
            <SubscriptionsOutlinedIcon style={{ fontSize: "28px", marginRight: "18px"}}/>
            Subscriptions
      </div>
      </Link>

      <Link
            to="/newest"
            style={{ textDecoration: "none", color: "inherit"  }}
            
          >
      <div className={`sidebarTab ${'newest' === selectedId && 'active'}`} onClick={() => setSelectedId('newest')}>
            <ScheduleIcon style={{ fontSize: "28px", marginRight: "18px"}}/>
            Newest
      </div>
      </Link>

      <Link
            to="/terms"
            style={{ textDecoration: "none", color: "inherit"  }}
           
          >
      <div className={`sidebarTab ${'terms & pp' === selectedId && 'active'}`} onClick={() => setSelectedId('terms & pp')}>
            <SettingsOutlinedIcon style={{ fontSize: "28px", marginRight: "18px"}}/>
            Terms & PP
      </div>
    </Link>
      <div className="horizontalLine" style={{ margin: '10px 0' }}></div>

            <div className="sidebarBottomLinks">
                    <Link to="/">About</Link>
                    <Link to="/">Press</Link>
                    <Link to="/terms">Copyright</Link>
                    <Link to="/">Contact</Link>
                    <Link to="/">Creator</Link>
                    <Link to="/">Advertise</Link>
                    <Link to="/">Developers</Link>
              </div>
              <div className="sidebarBottomLinks">
                <Link to="/terms">Terms</Link>
                <Link to="/terms">Privacy</Link>
                <Link to="/terms">Policy &amp; Safety</Link>
                <Link to="/">How Yivster works</Link>
                <Link to="/">Test new features</Link>
                <h3>&copy; 2024 Yivster LLC</h3>
            </div>
  </div>
</div>
      ) : (
        <nav class="mini-sidebar">

            <Link
            to="/"
            style={{ textDecoration: "none", color: "inherit"  }}
            
          >
      <div class="mini-sidebar-link">
     <HomeIcon />
     {/* <span className='material-symbols-rounded'>Home</span> ///  <img src={Home}/> */}
      <div> Home </div>
      </div>
      </Link>

      <Link
            to="/trending"
            style={{ textDecoration: "none", color: "inherit"  }}
          >
      <div class="mini-sidebar-link">
       <TrendingUpIcon />
        <div>Trending</div>
      </div>
      </Link>

      <Link
            to="/subscriptions"
            style={{ textDecoration: "none", color: "inherit"  }}
          >
      <div class="mini-sidebar-link">
      <SubscriptionsOutlinedIcon />
        <div>Subscriptions</div>
      </div>
      </Link>

      <Link
            to="/newest"
            style={{ textDecoration: "none", color: "inherit"  }}
            className="logo-wrapper"
          >
      <div class="mini-sidebar-link">
      <ScheduleIcon />
        <div>Newest</div>
      </div>
      </Link>

      <Link
            to="/terms"
            style={{ textDecoration: "none", color: "inherit"  }}
          >
      <div class="mini-sidebar-link">
      <SettingsOutlinedIcon />
        <div> Terms &amp; PP </div>
      </div>
      </Link>

    </nav>
      )}
    </>
  );
};

export default Navbar;

