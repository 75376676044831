import { Grid, Typography, useTheme } from "@mui/material";
// import { styled } from "@mui/system";
import { useMediaQuery, Link } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { format } from "timeago.js";
import axiosClient from "../utils/client";
import styled from "styled-components";
import "../css/recommendation.css";

/* const VideoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  /*  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Stack elements vertically on mobile
  }, 
})); */

const VideoContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: 24, // Use a fixed pixel value
  borderRadius: 6, // Use a fixed pixel value
  "@media (max-width: 600px)": {
    flexDirection: "column", // Stack elements vertically on mobile
  },
});


/* const Thumbnail = styled("img")(({ theme }) => ({
  width: "150px",
  height: "100px",
  objectFit: "cover",
  borderRadius: "5px",
  [theme.breakpoints.down("sm")]: {
    width: "100%", // Full width on mobile
    height: "220px", // Adjust height on mobile
    alignSelf: "center", // Center the image vertically on mobile
  }, 
})); */

const Thumbnail = styled("img")({
  width: "150px",
  height: "100px",
  objectFit: "cover",
  borderRadius: "5px",
  "@media (max-width: 600px)": {
    width: "100%", // Full width on mobile
    height: "220px", // Adjust height on mobile
    alignSelf: "center", // Center the image vertically on mobile
  },
});


/* const ThumbnailLInk = styled(Link)(({ theme }) => ({
  display: "block",
   [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(1), // Add some spacing between thumbnail and text on mobile
    width: "100%",
  }, 
})); */

const ThumbnailLInk = styled(Link)({
  display: "block",
  "@media (max-width: 600px)": {
    marginBottom: 8, // Add some spacing between thumbnail and text on mobile
    width: "100%",
  },
});


const TextContainer = styled("div")(({ theme }) => ({
  marginLeft: "8px",
  wordWrap: "break-word", // Add word-wrap property to enable text wrapping
}));

/* const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  lineHeight: 1.2,
  color: theme.palette.mode === "dark" ? "lightgrey" : "grey", // Adjust color based on theme mode
  fontSize: "15px", // Adjust font size
  margin: "-2px 0 0", // Remove margin
  whiteSpace: "pre-wrap", // Allow wrapping within words
  wordWrap: "break-word", // Add word-wrap property to enable text wrapping
  overflowWrap: "break-word", // Add overflow-wrap property as well for compatibility
  wordBreak: "break-word", // Add word-break property for better handling of long words
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px", // Adjust the font size on mobile
  },
})); */

const Title = styled.h6`
  font-weight: bold;
  line-height: 1.2;
  color: var(--text-main); // Adjust color based on theme mode
  font-size: 15px; // Adjust font size
  margin: -2px 0 0; // Remove margin
  white-space: pre-wrap; // Allow wrapping within words
  word-wrap: break-word; // Add word-wrap property to enable text wrapping
  overflow-wrap: break-word; // Add overflow-wrap property as well for compatibility
  word-break: break-word;
`;

// const ChannelContainer = styled("div")({
//   display: "flex",
//   alignItems: "center",
// });

const ChannelName = styled.h6`
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--text-main);
  font-size: 14px; // Adjust font size
`;

const ViewsAndDate = styled(Typography)({
  color: "#888",
  fontSize: "14px",
});

const RecommendCards = ({ type, video }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [channel, setChannel] = useState({});
  // const [error, setError] = useState([])

  //use effect to get channel information
  useEffect(() => {
    const fetchChannel = async () => {
      //channel information will be filled with whatever we got back from the userId
      const res = await axiosClient.get(`/api/users/find/${video.userId}`);
      //set videos === all the data sent back from axios (so all random videos)
      setChannel(res.data);
      //console.log(res.data)
    };
    fetchChannel();
    //whenever the user id is changed the function will be fired
  }, [video.userId]);

  return (
    <div class="grid-container">
      <div class="grid-item">
        <div class="video-container">
          <Link href={`/video/${video._id}`} class="thumbnail-link">
            <img src={video.imgUrl} alt="Video Search Result" class="recommended-thumbnail" />
          </Link>
          <div class="text-container">
            <h6 class="recommend-title">
              <Link href={`/video/${video._id}`} class="link">
                {video.title}
              </Link>
            </h6>
            <Link href={`/channel/${channel._id}`} class="channel-link">
              <span class="channel-name"> {channel.name}</span>
            </Link>
            <p class="views-and-date">
              {video.views} views • {format(video.createdAt)}
            </p>
          </div>
        </div>
  </div>
</div>
  );
};

export default RecommendCards;
