import { useState, useRef, useEffect } from "react";
// import { styled } from "@mui/material/styles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";
import axiosClient from "../utils/client";
import { BASE_URL } from "../config";

// Create a Search component that renders a div with some styles
const Search = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

// Create a SearchResultsDrop component that renders a div with some styles
const SearchResultsDrop = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  border-radius: 10px;

  &.hidden {
    display: none;
  }
`;

// Use the theme object in the styles
const SearchContainer = styled.div`
  display: flex;
  align-items: stretched;
  border-radius: 25px;
  height: 40px;
  border: 1px solid #ccc;
  overflow: hidden;
  width: 560px;


  border-color: ${(props) => props.borderColor};
  justify-content: center; // Add this line
  align-items: center; // Add this line
  background-color: white;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

// Create a styled component for input
const StyledInputBase = styled.input`
  padding-left: 10px;
  flex-grow: 1;
  border: none;
  outline: none; // add this line
  box-shadow: none; // add this line
`;

// Create styled components for each element
const Paper = styled.div`

  border-radius: 10px;
  background-color: #f0f0ff;
`;

const List = styled.div`
  padding: 0;
  max-height: 300px;
  min-height: 40px;
  overflow: auto;
  list-style: none;
`;

const ListItem = styled.ul`
  padding: 0;
`;

const ListItemButton = styled.li`
  // Use props to change the color based on the title
  padding: 10px;
  color: black;
  font-size: 18px;

  &:hover {
    cursor: pointer;
    background-color: lightgrey;
  }
`;

// Create a styled component for Button
const Button = styled.button`
  height: 40px;
  width: 40px;
  padding-top: 4px;
  border: none;
  &:hover {
    background-color: lightgrey;
    cursor: pointer;
  }
`;

// Create a styled component for CircularProgress
const StyledCircularProgress = styled(CircularProgress)`
  height: 20px; // set the height of the component
  width: 20px; // set the width of the component
`;

const SearchBar = ({ query, handleSearchQuery, handleMobileSearchClose }) => {
  const navigate = useNavigate();
  const [isDropHidden, setIsDropHidden] = useState(true);
  const [isFocused, setIsfocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchCompleted, setSearchCompleted] = useState(false);

  const timerRef = useRef();
  const searchLimit = 1;
  const showDropResults = query.length > searchLimit;

  useEffect(() => {
    if (query.length > searchLimit) {
      getSearchData(query);
    }
  }, [query]);

  const clearSearch = () => {
    handleSearchQuery("");
    setIsDropHidden(true);
    setSearchSuggestions([]);
  };

  const handleSearchSubmit = () => {
    handleMobileSearchClose();
    setIsDropHidden(true);
    navigate(`/search?q=${query}`);
  };

  const updateSearch = (event) => {
    const inputValue = event.target.innerText;
    handleSearchQuery(inputValue);
    setIsDropHidden(true);
    setSearchSuggestions([]);
    handleMobileSearchClose();
    navigate(`/search?q=${inputValue}`);
  };

  const handleSearch = (event) => {
    console.log("handleSearchQuery called with before:", event.target.value);
    const inputValue = event.target.value;
    console.log("handleSearchQuery called with: after", event.target.value);

    handleSearchQuery(inputValue);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (inputValue.length > searchLimit) {
      timerRef.current = window.setTimeout(() => {
        getSearchData(inputValue);
      }, 600);
    } else {
      setSearchSuggestions([]);
      setIsDropHidden(true);
    }
  };

  const getSearchData = async (query) => {
    console.log("getSearchData called with query:", query);
    setIsLoading(true);

    try {
      const response = await axiosClient.get(
        `${BASE_URL}api/videos/autocomplete`,
        {
          params: { query },
        }
      );

      setSearchSuggestions(response.data);

      setIsDropHidden(false);
      console.log(response.data);
      console.log(BASE_URL);
    } catch (error) {
      console.log("Error");
      console.log(BASE_URL);
    } finally {
      setIsLoading(false);
      // set searchCompleted to false in finally block
      setSearchCompleted(true);
    }
  };

  return (
    <Search>
      <SearchContainer borderColor={isFocused ? "rgba(25, 118, 210, 0.5)" : ""}>
        <StyledInputBase
          placeholder="Search…"
          onChange={handleSearch}
          inputProps={{ "aria-label": "search" }}
          autoFocus
          fullWidth
          value={query}
          onFocus={() => setIsfocused(true)}
          onBlur={() => setIsfocused(false)}
        />
        {showDropResults && (
          <Button sx={{ minWidth: 0 }} onClick={clearSearch}>
            <CloseIcon />
          </Button>
        )}
        <Button
          sx={{
            borderRadius: 0,
          }}
          disabled={isLoading}
          onClick={handleSearchSubmit}
        >
          {isLoading ? (
            <CircularProgress style={{ height: 20, width: 20 }} />
          ) : (
            <SearchIcon />
          )}
        </Button>
      </SearchContainer>
      <SearchResultsDrop
        className={`search-dropdown ${isDropHidden ? "hidden" : ""}`}
      >
        <Paper>
          <List>
            {searchSuggestions.map(({ title }) => (
              <ListItem key={title}>
                <ListItemButton onClick={updateSearch}>{title}</ListItemButton>
              </ListItem>
            ))}
            {searchSuggestions.length === 0 && searchCompleted && (
              <ListItem style={{ paddingLeft: "10px", marginTop: "10px" }}>
                No Results Found
              </ListItem>
            )}
          </List>
        </Paper>
      </SearchResultsDrop>
    </Search>
  );
};

export default SearchBar;
