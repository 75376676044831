import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Comment from "./Comment";
import { Typography, Button, Input } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createComment, fetchStart, fetchFailure } from "../redux/commentSlice";
import { Link } from "react-router-dom";
import Error from "../components/Error";
import Avatar from "@mui/material/Avatar";
import { useAlert } from "react-alert";
import axiosClient from "../utils/client";
import { useTheme } from "styled-components";

const _ = require("lodash");

const Container = styled("div")``;

const NewComment = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AvatarProfile = styled("img")`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

// const Input = styled.input`
//   border: none;
//   border-bottom: 1px solid ${({ theme }) => {
//     console.log(theme, 'themethemethemethemethemethemethemethemethemetheme');
//     return theme.soft
//   }};
//   color: ${({ theme }) => theme.text};
//   background-color: transparent;
//   outline: none;
//   padding: 5px;
//   width: 100%;
// `;

const Comments = ({ videoId }) => {
  const { currentUser } = useSelector((state) => state.user);
  const { currentComment } = useSelector((state) => state.comment);

  const [comments, setComments] = useState(currentComment);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const alert = useAlert();

  const dispatch = useDispatch();

  const theme = useTheme();

  useEffect(() => {
    let difference = _.isEqual(currentComment, comments);
    if (!difference) {
      setComments(currentComment);
    }
  }, [currentComment]);

  useEffect(() => {
    // console.log('in comments useeffect...........')
    const fetchComments = async () => {
      dispatch(fetchStart());
      try {
        const res = await axiosClient.get(`/api/comments/${videoId}`);

        setComments(res.data);

        // dispatch(createComment(res.data))
      } catch (err) {
        dispatch(fetchFailure());
      }
    };
    fetchComments();
  }, [videoId, dispatch]);

  const handleComment = async (e) => {
    //must submit some text
    e.preventDefault();
    try {
      //passing in the comment and videoId
      const res = await axiosClient.post(`/api/comments`, {
        desc: comment,
        videoId,
      });
      setComment("");
      //desctructing all of the comments and adding the new comment to the array
      setComments([...comments, res.data]);

      // dispatch(createComment(currentComment._id))
      // dispatch(createComment(res.data))
    } catch (err) {
      alert.error("You are not authorized!");
    }
  };

  return (
    <div>
      <div className="new-comment">
        <Avatar
          src={currentUser?.img ? currentUser?.img : ""}
          alt="User Avatar"
        />
        <Input
          style={{ color: 'var(--text-main)' }}
          sx={{ width: "100%" }}
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button variant="contained" onClick={handleComment}>
          Post
        </Button>
      </div>
      {comments && comments.length > 0
        ? comments.map((comment) => (
            <Comment key={comment._id} comment={comment} videoId={videoId} />
          ))
        : ""}
    </div>
  );
};

export default Comments;
