import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography, Button, Avatar, Box } from "@mui/material";
import { format } from "timeago.js";
import { useLocation, Link } from "react-router-dom";
import { useTheme } from "styled-components";
import "../css/video.css"


import {
  Paid as PaidIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  ArrowBack as ArrowBackIcon,
  OutlinedFlag as OutlinedFlagIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  ThumbDownOffAltOutlined as ThumbDownOffAltOutlinedIcon,
  ReplyOutlined as ReplyOutlinedIcon,
} from "@mui/icons-material";

import { fetchSuccess, like, dislike } from "../redux/videoSlice";
import axiosClient from "../utils/client";
import { subscription } from "../redux/userSlice";
import Comments from "../components/Comments";
import Recommendation from "../components/Recommendation";
import Modal from "../components/Modal";
import StripeModal from "../components/StripeModal";
import ReportModal from "../components/ReportModal";

import {
  Container,
  Content,
  SideBar,
  SideBarInner,
  Title,
  Details,
  Info,
  Buttons,
  Channel,
  ChannelInfo,
  Image,
  ChannelDetail,
  ChannelName,
  ChannelCounter,
  CommentsContainer,
  CommentsBackdrop,
  VideoWrapper,
  VideoFrame,
} from "./videoStyles";

const Video = () => {
  const { currentUser } = useSelector((state) => state.user);
  const { currentVideo } = useSelector((state) => state.video);
  const timeWatched = useRef(0);
  const interval = useRef(null);
  const videoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to track the description expansion
  const [expandMobileComments, setExpandMobileComments] = useState(false); // State to track the description expansion
  const dispatch = useDispatch();
  //getting the path which is the video ID
  const path = useLocation().pathname.split("/")[2];
  //getting the url
  const url = window.location.href;
  const [channel, setChannel] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const videoRes = await axiosClient.get(`/api/videos/find/${path}`);
        const channelRes = await axiosClient.get(
          `/api/users/find/${videoRes.data.userId}`
        );
        setChannel(channelRes.data);
        console.log(videoRes.data);
        //SET THE CURRENT VIDEO BACK TO REDUX STATE
        dispatch(fetchSuccess(videoRes.data));
      } catch (err) {}
    };
    fetchData();
  }, [path, dispatch]);

  const handleLike = async () => {
    await axiosClient.put(`/api/users/like/${currentVideo._id}`);
    dispatch(like(currentUser._id));
  };
  const handleDislike = async () => {
    await axiosClient.put(`/api/users/dislike/${currentVideo._id}`);
    dispatch(dislike(currentUser._id));
  };
  const handleSub = async () => {
    currentUser?.subscribedUsers.includes(channel._id)
      ? await axiosClient.put(`/api/users/unsub/${channel._id}`)
      : await axiosClient.put(`/api/users/sub/${channel._id}`);
    dispatch(subscription(channel._id));
  };

  useEffect(() => {
    // If the video is not available then do nothing
    if (!videoRef.current) return;
    // If the interval is already running then do nothing, if timeWatched is not 0 the interval has already run and completed (added 1 view to the viewcount)
    if (interval.current || timeWatched.current !== 0) return;
    interval.current = setInterval(async () => {
      if (!videoRef.current) return;
      // If the video is paused then do nothing
      if (videoRef.current.paused) return;
      // Add 1 to the time watched every second
      timeWatched.current += 1;
      // If the video duration is less than 10 seconds and the user has watched the entire video, send a request to the server to add a view
     // console.log(timeWatched.current);
      //console.log(videoRef.current.duration);
      if (
        videoRef.current.duration < 10 &&
        timeWatched.current >= Math.floor(videoRef.current.duration)
      ) {
        //console.log(timeWatched.current);
        // Stop the interval
        clearInterval(interval.current);
        //set the inteerval back to null
        interval.current = null;
        try {
          await axiosClient.put(`/api/videos/view/${currentVideo._id}`);
        } catch (err) {
          console.log(err.reponse.data);
        }
      }
      // If the time watched is at least 10 seconds or the entire video duration, send a request to the server to add a view
      if (timeWatched.current >= Math.min(videoRef.current.duration, 10)) {
        // Stop the interval
        clearInterval(interval.current);
        //set the inteerval back to null
        interval.current = null;
        try {
          await axiosClient.put(`/api/videos/view/${currentVideo._id}`);
        } catch (err) {
          console.log(err.reponse.data);
        }
      }
      // Run this code every 1 second
    }, 1000);
    //clear the interval when the user leaves the
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        //set the interval back to null
        interval.current = null;
      }
    };
  }, [videoRef.current]);

  /*https://video-react.js.org/components/player/ */
  //add ts files for video learn ffmpeg
  const toggleDescription = () => {
    setExpanded(!expanded);
  };
  const maxDescriptionLength = 100; // Maximum number of characters to display in the description

  const handleMobileComments = () => {
    setExpandMobileComments((prev) => !prev);
  };

  const theme = useTheme();

  return (
    <>
      <Container sx={{ pb: 5 }}>
            {currentVideo && ( 
           /*  <div class="play-video">
                <video 
                  className="video-player"
                  controls 
                  ref={videoRef}
                  src={currentVideo?.videoUrl}
                  type="video/mp4">
                </video> */
              <div class="row">
                <div class="play-video">
                  <video 
                    controls 
                    ref={videoRef}
                    src={currentVideo?.videoUrl}
                    type="video/mp4"
                  />
                </div>

                <h3 class="vid-title"> {currentVideo?.title} </h3>

                <div class="play-video-info">
                    <p className="video-views"> {currentVideo?.views} views • {format(currentVideo?.createdAt)}{" "}</p>
                    
                <div class="Buttons">
                <button className="like-btn" onClick={handleLike}>
                  {currentVideo?.likes?.includes(currentUser?._id) 
                  ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
                   Likes {currentVideo?.likes?.length}
                </button>

                <button className="dislike-btn" onClick={handleDislike}>
                  {currentVideo?.dislikes?.includes(currentUser?._id) 
                  ? <ThumbDownIcon /> : <ThumbDownOffAltOutlinedIcon />}
                  Dislikes {currentVideo?.dislikes?.length}
                </button>

                <button className="tip-btn" 
                  onClick={() => setOpenStripeModal(true)}>
                  <PaidIcon style={{ color: "#70E000" }} />
                  Tip
              </button>
                
              <button className="report-btn" 
                onClick={() => setOpenReportModal(true)}>
                <OutlinedFlagIcon />
               Report
              </button>

                  <button className="share-btn" 
                    onClick={() => setOpen(true)}>
                    <ReplyOutlinedIcon />
                    Share
                </button>
                </div>
            </div>
              
                <div class="publisher">
                <Link
                  to={`/channel/${channel._id}`}
                  style={{ textDecoration: "none" }}
                >
                {channel.img ? (
                    <img
                      src={channel.img}
                      style={{ border: "3px solid #3F8EFC" }}
                    />
                  ) : (
                    <Avatar style={{ border: "3px solid #3F8EFC", width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "15px",
                    objectFit: "cover",
                    aspectRatio: "16/9" }} />
                  )}
                </Link>
                    <div class="channel-info">
                        <p className="channel-name">{channel.name} </p>
                        <span className="channel-subs"> {channel.subscribers} subscribers </span>
                    </div>
                    <button class="sub-btn" type="button"onClick={handleSub}> {currentUser?.subscribedUsers?.includes(channel._id)
                  ? "Subscribed"
                  : "Subscribe"}
                  </button>
                </div>
                <div className="vid-description">
                  <div className="video-desc-wrapper">
                      <span>
                          <p className="video-description" style={{display: 'inline'}}>
                              {expanded
                                  ? currentVideo?.desc
                                  : `${currentVideo?.desc.slice(0, maxDescriptionLength)}`}
                          </p>
                          <p className="see-more-btn" onClick={toggleDescription} style={{display: 'inline'}}>
                              {" "}
                              ...{expanded ? "See Less" : "See More"}
                          </p>
                      </span>
                  </div>
              </div>
              <Button
              variant="contained"
              size="large"
              onClick={handleMobileComments}
              sx={{ width: "100%", display: { sm: "block", md: "none" } }}
            >
              Show Comments
            </Button>
            <CommentsBackdrop
              className={`${expandMobileComments ? "active" : ""}`}
            >
              <CommentsContainer>
                <Box
                  sx={{
                    display: "flex",
                    py: 2,
                    zIndex: 9,
                    // background: "#fff",
                    top: "-11px",
                    position: { xs: "sticky", md: "static" },
                  }}
                >
                  <Button
                    onClick={handleMobileComments}
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    sx={{ mr: "auto", display: { md: "none" } }}
                  >
                    Back to video
                  </Button>
                  <Typography
                    variant="subtitle2"
                  >
                    {currentVideo?.count}{" "}
                    {currentVideo?.count > 1 ? "Comments" : "Comment"}
                  </Typography>
                </Box>
                <Comments videoId={currentVideo?._id} />
              </CommentsContainer>
            </CommentsBackdrop>
            </div>
            )}
        <SideBar>
          <SideBarInner>
            <Recommendation tags={currentVideo?.tags} />
          </SideBarInner>
        </SideBar>
    </Container>
     {open && <Modal setOpen={setOpen} url={url} />}
     {openStripeModal && (
       <StripeModal
         setOpenModal={setOpenStripeModal}
         videoId={currentVideo?._id}
         channelName={channel.name}
       />
     )}
     {openReportModal && (
       <ReportModal
         setOpenModal={setOpenReportModal}
         videoId={currentVideo?._id}
         channelName={channel.name}
       />
     )}
     </>
  );
};
export default Video;
