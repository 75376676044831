import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { format } from "timeago.js";
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import HoverVideoPlayer from "react-hover-video-player";
import styled from "styled-components";
import { useTheme } from "styled-components";
import thumbnail from "../assets/images/thumbnail-1.webp";

import "../css/card.css";

const Card = ({ type, video }) => {
  const theme = useTheme();

  const getVideoDuration = (duration) => {
    let returnValue = "00:00";
    if (duration) {
      // let minutes = Math.floor(duration / 60);
      // let seconds = Math.floor(duration % 60);
      // minutes = minutes.toString().padStart(2, '0');
      // seconds = seconds.toString().padStart(2, '0');
      // returnValue = `${parseInt(minutes)} : ${parseInt(seconds)}`

      returnValue = new Date(duration * 1000).toLocaleTimeString([], {
        minute: "numeric",
        second: "2-digit",
      });
    }
    return returnValue;
  };

  const [imageIsLoaded, setImageLoaded] = useState(false);

  const handleLoading = () => {
    setImageLoaded(true);
  };

  return (
    <div class="video-preview" type={type}>
    <div class="thumbnail-row">
     <div class="thumbnail-container">
        <Link to={`/video/${video._id}`} className="card-link">
          <HoverVideoPlayer
            videoSrc={video.videoUrl}
            pausedOverlay={
              <>
                {!imageIsLoaded && (
                  <Skeleton
                    sx={{
                      height: 177,
                      position: "absolute",
                      backgroundColor: "background.paper",
                      width: "100%",
                     aspectRatio: "16/9",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                )}
                <img
                  className="thumbnail"
                  src={video.imgUrl}
                  alt=""
                  onLoad={handleLoading}
                />
              </>
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </Link>
       </div>
        {/* </div> */}
        <div class="video-time"> {getVideoDuration(video.duration)}</div>
      </div>
      <div class="video-info-grid" type={type}>
        <Link
          to={`/channel/${video?.userData?._id}`}
          style={{ textDecoration: "none" }}
        >
          <div class="channel-picture">
            <Avatar src={video?.userData?.img} />
          </div>
        </Link>
        <div class="video-info">
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`/video/${video._id}`}
          >
            <p
              class="video-title"
            >
              {video.title}
            </p>
          </Link>

          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`/channel/${video?.userData?._id}`}
          >
            <p class="video-author">{video?.userData?.name}</p>
          </Link>

          <p class="video-stats">
            {" "}
            {video.views} views &bull; {format(video.createdAt)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;