import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";
import { useNavigate } from "react-router-dom";

import Resizer from 'react-image-file-resizer';
import { useAlert } from "react-alert";
import axiosClient from "../utils/client";


const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000a7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  background-color: #343a40;
  color: ${({ theme }) => theme.text};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Title = styled.h1`
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: -10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Limit = styled.h2`
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: -10px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const GBLimit = styled.h2`
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 12px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.soft};
  color: white;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  z-index: 999;
  width: 100%;

  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`;

const Desc = styled.textarea`
  border: 1px solid ${({ theme }) => theme.soft};
  color: white;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  width: 100%;

  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`;

const Button = styled.button`
  border-radius: 7px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: rgb(63, 142, 252);
  color: white;
`;

const Label = styled.label`
  font-size: 14px;
  color: white;
`;

const InputLabel = styled.label`
  font-size: 10px;
  color: white;
  margin-top: -10px;
  margin-bottom: -10px;
`;

const Upload = ({ setVideoOpen }) => {
  const [img, setImg] = useState(undefined);
  const [video, setVideo] = useState(undefined);
  const [imgPerc, setImgPerc] = useState(0);
  const [videoPerc, setVideoPerc] = useState(0);
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState([]);
  const alert = useAlert();
  const navigate = useNavigate();
  // const ffmpegRef = useRef(new FFmpeg());


  const handleChange = (e) => {
    setInputs((prev) => {
      // taking the previous inputs and return the new ones
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleTags = (e) => {
    setTags(e.target.value.split(","));
  };


  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      Resizer.imageFileResizer(
        file,
        340,  // Width
        191,  // Height
        'JPEG',  // Format
        60,  // Quality (0-100)
        0,  // Rotation (0, 90, 180, or 270 degrees)
        (uri) => {
          // uri is the resized and compressed image data
          // You can use it for display or further processing
          console.log('this is after resize........', uri);
          setImg(uri)
        },
        'file',  // Output type ('base64', 'blob', or 'file')
        350,  // Max file size (optional)
        192   // Max height (optional)
      );
    }
  };

  // const compressVideo = async (inputFile) => {
  //   const ffmpeg = ffmpegRef.current;
  //   await ffmpeg.load();
  //   await ffmpeg.writeFile('input.mp4', new Uint8Array(await inputFile.arrayBuffer()));
  //   await ffmpeg.exec(['-i', 'input.mp4', '-vf', 'scale=640:480', '-c:v', 'libx264', '-crf', '20', 'output.mp4']);
  //   const compressedVideo = await ffmpeg.readFile('output.mp4');
  //   return new Blob([compressedVideo.buffer], { type: 'video/mp4' });
  // };

  const handleVideoFileInputChange = async (event) => {
    const maxFileSizeInBytes = 2 * 1024 * 1024 * 1024;
    const file = event.target.files[0];

    if (file) {
      if (file.size <= maxFileSizeInBytes) {
        // File size is within the allowed limit (<= 2 GB)
        // You can proceed with handling the file
        console.log(`File size: ${file.size} bytes`);
        // const compressedFile = await compressVideo(file);
        setVideo(file)
      } else {
        // File size exceeds the allowed limit
        alert('File size exceeds the maximum allowed size. Please choose a smaller file.');
        // Clear the input field to prevent submission
        event.target.value = '';
      }
    }
  };


  const uploadFile = (file, urlType) => {
    try {
      const storage = getStorage(app);
      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // if its image url uodate image percantange if not set video percentage
          urlType === "imgUrl"
            ? setImgPerc(Math.round(progress))
            : setVideoPerc(Math.round(progress));
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          setVideoOpen(false);
          alert.error("Upload error: Files not uploaded!");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setInputs((prev) => {
              console.log(downloadURL);
              return { ...prev, [urlType]: downloadURL };
            });
          });
        }
      );
    } catch (err) {
      setVideoOpen(false);
      alert.error("Upload error: Files not uploaded!");
    }

  };

  useEffect(() => {
    // if there is a video call the upload function and passing in the video
    video && uploadFile(video, "videoUrl");
    // whenever we add a new video file it will fire this function
  }, [video]);

  useEffect(() => {
    // if there is a image call the upload function and passing in the video
    img && uploadFile(img, "imgUrl");
    // whenever we add a new image file it will fire this function
  }, [img]);




  const getDuration = async (file) => {
    const url = URL.createObjectURL(file);

    return new Promise((resolve) => {
      const audio = document.createElement("audio");
      audio.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      audio.preload = "metadata";
      audio.appendChild(source);
      audio.onloadedmetadata = function () {
        resolve(audio.duration)
      };
    });
  }

  const handleUpload = async (e) => {
    try {
      e.preventDefault();

      // fetching the video duration in seconds
      const duration = await getDuration(video);
      //sending the video to mongo

      const res = await axiosClient.post("/api/videos", { ...inputs, tags, duration });
      console.log('res................', res)
      if (res.status !== 200) {
        alert.error("Something went wrong, files not uploaded!");
      }
      //console.log(res);
      setVideoOpen(false);
      /* if the video uploaded naviagte me to the video page
       res.status === 200 && navigate(`/api/video/${res.data._id}`); */
      //if the video uploaded naviagte me to the home page
      res.status === 200 && navigate(`/`);
    } catch (err) {
      setVideoOpen(false);
      alert.error("Upload error: Files not uploaded!");
    }
  };

  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setVideoOpen(false)}>X</Close>
        <Title>Upload a New Video</Title>
        <Limit> 20 Minute Video Limit </Limit>
        <GBLimit> 2 GB Video File Size Limit </GBLimit>

        <Label>Video:</Label>
        {videoPerc > 0 ? (
          "Uploading:" + videoPerc + "%"
        ) : (
          <Input
            type="file"
            accept="video/*"
            onChange={(e) => handleVideoFileInputChange(e)}
            id="video1"
            required
          />
        )}
        <InputLabel> *Required </InputLabel>

        <Input
          type="text"
          placeholderColor="white"
          placeholder="Title"
          name="title"
          onChange={handleChange}
          required
        />
        <InputLabel> *Required </InputLabel>

        <Desc
          placeholder="Description"
          type="text"
          placeholderColor="white"
          name="desc"
          rows={8}
          onChange={handleChange}
          required
        />
        <InputLabel> *Required </InputLabel>
        <Input
          type="text"
          placeholderColor="white"
          placeholder="Separate the tags with commas."
          onChange={handleTags}
          required
        />
        <InputLabel> *Required </InputLabel>

        <Label>Image:</Label>

        {imgPerc > 0 ? (
          "Uploading:" + imgPerc + "%"
        ) : (
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e)}
          />
        )}
        <InputLabel> *Required </InputLabel>

        <Button onClick={handleUpload}> Upload </Button>
      </Wrapper>
    </Container>
  );
};

export default Upload;
