import React, { useState } from 'react';
import SignInModal from '../components/SignInModal';


const SignIn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{  
      position: "fixed", /* Fixed/sticky position */
      top: 0, /* Place the button at the top of the page */
      left: 0, /* Place the button at the start of the page */
      height: "100vh", /* Full height */
      width: "100vw", /* Full width */
      backgroundColor: "var(--primary-color)" /* Background color */
  }}>
    <SignInModal />
  </div>
  );
};

export default SignIn;
