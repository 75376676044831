import React from "react";
import Card from "../components/Card";
import Navbar from "../components/Navbar";
import { Grid, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import styled from "styled-components";
import CardSkeleton from "../components/CardSkeleton";
import { generateKey } from "../utils/helpers";
import axiosClient from "../utils/client";
// import AdBanner from "../assets/images/youtubeBanner.png";
import AdBanner from "../assets/images/3.png";
import YivLogo from "../assets/images/logo.png";
import axios from 'axios';
import "../css/home.css";

import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  SubscriptionsOutlined as SubscriptionsOutlinedIcon,
} from "@mui/icons-material";

const AuthRequiredBox = styled(Box)`
  display: flex;
  padding-top: 64px;
  justify-content: center;
  min-height: calc(100vh - 125px);
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};
`;

const Home = ({ type }) => {
  const [videos, setVideos] = useState([]);
  // const [originalVideos, setOriginalVideos] = useState([]);

  const categoryData = ["All", "Gaming", "Javascript", "Python", "Music", "Mixes", "Lives", "Comedy", "Programming", "Lofi Songs", "Couples", "Teenage Programmer", "Arijit Singh", "T-Series"]

  const [categories, setCategories] = useState([]);
  const [onDisplay, setonDisplay] = useState(videos);

  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('All');


  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observerTarget = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authIsRequired, setAuthIsRequired] = useState(false);

  const itemsPerPage = 12;

  const fetchVideos = async (page) => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      try {
        const res = await axiosClient.get(
          `/api/videos/${type}/${page}/${itemsPerPage}`
        );
        setHasMore(res?.data?.hasMore);
        setVideos((prev) => [...prev, ...res?.data?.data]);
      } catch ({ message }) {
        console.error(message);
      } finally {
        setIsLoading(false);
      }
    }
  };
 
  
  useEffect(() => {
    if (type === "sub" && currentUser === null) {
      setAuthIsRequired(true);
    }
  }, [currentUser, type]);

  useEffect(() => {
    if (authIsRequired) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => {
            fetchVideos(prev);
            return prev + 1;
          });
        }
      },
      { threshold: 1 }
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, []);
/* 
  const getCategories = async () => {
    try {
      const response = await axios.get('/api/videos/categories');
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error(error);
      console.log(error.message);
      return [];
    }
  };

const fetchVideosByCategories = async (category) => {
  setIsLoading(true); // Start loading
  try {
    // Make a GET request to the /videos/by-category endpoint with the category in the request query parameters
    const response = await axios.get(`/videos/find/by-category?category=${category}`);
    console.log(response.data.data);
    // Update the videos state with the fetched data
    setVideos(response.data.data);
    console.log(videos);
  } catch (error) {
    // Handle any errors that occur
    console.error(error);
    console.log(error.message);
    console.log(error.response);
  } finally {
    setIsLoading(false); // Stop loading
  }
}; */


/* useEffect(() => {
  // This useEffect hook runs once when the component mounts
  const fetchCategories = async () => {
    // Asynchronous function to fetch the tags
    const data = await getCategories(); // Call the getTags function to fetch the tags
    console.log(data);
    setCategories(data);
    console.log(data);
  };
  fetchCategories(); // Call the fetchTags function to fetch and set the tags 
}, []); */


/* useEffect(() => {
  if (selectedTag === 'All') {
    console.log('Selected tag:', selectedTag);
    fetchVideos();
  } else {
    fetchVideosByCategories(selectedTag);
  }
}, [selectedTag, fetchVideos, fetchVideosByCategories]);  */

// This useEffect is triggered when selectedTag changes and it's 'All'
//works
/* useEffect(() => {
  if (selectedTag === 'All') {
    console.log('Selected tag:', selectedTag);
    fetchVideos();
  }
}, [selectedTag, fetchVideos]); 

// This useEffect is triggered when selectedTag changes and it's not 'All'
useEffect(() => {
  if (selectedTag !== 'All') {
    fetchVideosByCategories(selectedTag);
  }
}, [selectedTag]); */

/*    const handleTagClick = async (category) => {
  //setSelectedTag(tag);
  //await fetchVideosByTag(tag); 
  console.log('Selected category:', category);
  setSelectedTag(category);
  console.log('Selected category:', category);
};   */


  if (authIsRequired) {
    return (
      <AuthRequiredBox>
        <Box sx={{ textAlign: "center", minHeight: "100vh" }}>
          <SubscriptionsOutlinedIcon sx={{ fontSize: 100 }} />
          <Typography variant="h4">Don't miss new videos</Typography>
          <Typography sx={{ my: 2 }}>
            Sign in to see the latest videos from your subbed channels
          </Typography>
          <Button
            size="large"
            aria-label="account of current user"
            aria-haspopup="true"
            variant="outlined"
            sx={{ borderRadius: 5 }}
            onClick={() => navigate("/signin")}
            startIcon={<AccountCircleOutlinedIcon />}
          >
            SIGN IN
          </Button>
        </Box>
      </AuthRequiredBox>
    );
  }

  return (
      <>
  <div className="grid-container">
{/*     <div className="categoryContainer">
    <h3
      className={`categoryTab ${selectedTag === 'All' ? 'active' : ''}`}
      onClick={() => handleTagClick('All')}
      >
      All
    </h3>
      {categories.map((category) => (
        <h3
          className={`categoryTab ${selectedTag === category && 'active'}`}
          key={category}
          onClick={() => handleTagClick(category)}
        >
          {category}
        </h3>
      ))}
    </div> */}
   {/*   <div className="homeBanner">
      <img src={AdBanner} alt="Ad Banner" className="youtubeAdBanner" />
      <div className="infoBox">
       <div>
          <img src={YivLogo} alt="Youtube Logo" />
          <h2>Youtube Premium</h2>
        </div>
        <h1>Watch Everything you love</h1>
        <button>GET IT NOW</button> 
      </div>
    </div>  */}

{/*   <div className="video-grid">
  {
  selectedTag === 'All'
    ? videos.map((video) => <Card key={video.id} video={video} />)
    : videos
        .filter((video) => video.category === selectedTag)
        .map((video) => <Card key={video.id} video={video} />)
        || <div>No videos found for the selected tag.</div>
}
   {isLoading &&
    generateKey("CardSkeleton", itemsPerPage).map((key) => (
      <CardSkeleton key={key} />
    ))}
  <div ref={observerTarget}>&nbsp;</div>
</div>  */}

 
   <div class="video-grid">
          {videos.map((video) => (
            <Card video={video} />
          ))}
          {isLoading &&
            generateKey("CardSkeleton", itemsPerPage).map((key) => (
              <CardSkeleton />
            ))}
          <div ref={observerTarget}>&nbsp;</div>
        </div>  
  </div>
</>
  );
};

export default Home;

//fix skeelton part look at server side error might have to change code
